import { useForm } from '@/use/Base/useForm'
import { ref, watch, provide } from 'vue'
import { FormHandler } from '@/utils/use/formHandler'
import { useGetLot } from '@/use/Base/useGetLot'
import { useRoute } from 'vue-router'
import { Alert } from '@/extension/Alert/Alert'
import { Observer } from '@/core/Observer'
import { getLatestPrice } from '@/http/offer/offerApi'
import { useStore } from 'vuex'
import { formDataLotCreate } from '@/use/Form/Lot/Create/formData.lotCreate'
import { rulesLotCreate } from '@/use/Form/Lot/Create/rules.lotCreate'
import {
  criterionDisabled,
  setLotPositionsSum,
  setParametersIsSelected,
} from '@/use/Form/Lot/Create/lot.functions'
import { useValidateField } from '@/use/Form/useValidateField'

export function useTradeCreate (biddingProcedureController, offers, isLotBidder) {
  const isEditPayment = ref(false)
  const isEditDelivery = ref(false)
  const route = useRoute()
  const store = useStore()

  const formData = formDataLotCreate(isLotBidder)
  const lot = store.getters['lot/getLot']
  const { getPriceMinMaxCustom } = useValidateField(null, null, lot)
  const rules = rulesLotCreate(formData, isLotBidder)

  const { getLotCP } = useGetLot(isLotBidder, formData, true)

  watch(getLotCP, () => {
    isEditDelivery.value = getLotCP.value.delivery_ability_edit
    isEditPayment.value = getLotCP.value.payment_ability_edit
    formData.start_price = 0
  })

  const { maxCriterionDisabled, minCriterionDisabled } = criterionDisabled(formData)

  const checkIsValidPrice = () => {
    let pricesNotEmpty = false
    formData.positions.forEach(pos => {
      if (pos.price) {
        pricesNotEmpty = true
      }
    })
    if (!pricesNotEmpty) {
      return { isValid: false, message: 'Все позиции пустые, введите цену(ы)' }
    }
    let isValid = true
    let message = ''
      try {
        formData.positions.forEach(pos => {
          let min = null
          let max = null
          if (offers.last_offers && offers.last_offers.length) {
            try {
              const offer = offers.last_offers.find(offer => offer.answers.position.id === pos.id)
              const getMinAndMax = getPriceMinMaxCustom(pos, offer)
              min = getMinAndMax.min
              max = getMinAndMax.max
            } catch (e) {
              min = null
              max = null
            }
          }
          if (!pos.price && !formData.divisibility) return
          if (!pos.price) {
            isValid = false
            message = `Позиция "${pos.name}" должна иметь цену`
          } else {
              if (min && max && (pos.price < min || pos.price > max)) {
                isValid = false
                message = `Позиция "${pos.name}" должна иметь цену в диапазоне от ${min} до ${max}`
              }
            }
        })
      } catch (e) {
        isValid = true
      }
      return { isValid, message }
    }

  const formAction = async () => {
    try {
      const { isValid, message } = checkIsValidPrice()
      if (!isValid) {
        await Alert.show('error', message)
        return
      }
      setParametersIsSelected(formData)
      const lotId = route.params.id

      const positionList = []
      for (const position of formData.positions) {
        if (!position.price) continue
        position.offer = null
        const sortCount = offers.auth_user_last_offer.length ? Number(offers.auth_user_last_offer?.[0].sort) + 1 : 1
        const data = {
          answers: {
            participant_note: position.participant_note || '',
            position
          },
          position_id: position.id,
          sort: sortCount
        }

        if (position.price) {
          data.price = +position.price
        }

        positionList.push(data)
      }

      const resParticipate = await biddingProcedureController.participateBidder(formData, positionList)
      if (resParticipate) {
        await Alert.show('success', 'Вы успешно подали ставку')
        console.log(positionList[0].sort)
        await biddingProcedureController.setBidderDocuments(route.params.id, formData.supportingDocumentation, positionList[0].sort)
        const { data } = await getLatestPrice(route.params.id)
        store.commit('lot/setAuthLastPrice', data.auth_user_last_offer)
        await form.router.push({ name: store.getters['auth/getRole'], params: { id: lotId } })
      }
    } catch (e) {
      await Alert.show('error', e)
    }
  }

  const observer = new Observer()

  provide('observer', observer)
  observer.subscribe(() => setLotPositionsSum(formData))

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors,
    isEditPayment,
    isEditDelivery,
    maxCriterionDisabled,
    minCriterionDisabled
  }
}
