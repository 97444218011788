<script setup>
import VSpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'
import { inject, reactive, onBeforeMount, ref, provide } from 'vue'
import { ConsoleLogger } from '@/extension/Error/ConsoleLogger'
import { useRoute, useRouter } from 'vue-router'
import useLoading from '@/use/Base/useLoading'
import { getLot } from '@/http/lot/lotApi'
import { useStore } from 'vuex'
import { ParticipantService } from '@/service/ParticipantService'
import { useTradeCreate } from '@/use/Form/Bidder/trade/useTradeCreate'
import { Observer } from '@/core/Observer'
import { BIDDING_CONTROLLER } from '@/utils/consts'

const route = useRoute()
const router = useRouter()
const store = useStore()

const idPage = route.params.id
const lot = reactive({})
const isEdit = ref(false)
// condition is only read
const isBidder = ref(true)
const isOrganizer = ref(false)
const isSendParticipant = ref(false)
const isParticipant = ref(false)
const isFinishTrade = ref(false)
const historyOffers = reactive([])
const isHideButtonCreateTrade = ref(false)
const headerDetailPage = reactive([
  {
  title: 'Дата начала и время старта',
  description: 'Не указан'
  },
  {
    title: 'Дата окончания',
    description: 'Не указан'
  },
  {
    title: 'Статус',
    description: 'Не указан',
    decorator: {
      name: 'Status'
    }
  }
])
const offers = reactive({})
const observer = new Observer()
const biddingProcedureController = inject(BIDDING_CONTROLLER)

provide('observer', observer)

const { isLoading, finishLoading, startLoading } = useLoading()
const { formData, v$, validateField, serverErrors, onSubmit, isSend, isEditDelivery, isEditPayment } = useTradeCreate(biddingProcedureController, offers, isEdit)
const participantService = new ParticipantService({
  lotId: route.params.id,
  isParticipant: isParticipant,
  isSend: isSendParticipant
})

provide('formData', formData)
provide('v$', v$)
provide('validateField', validateField)
provide('serverErrors', serverErrors)

// Connect Websocket
const ws = inject('ws');
(function subChannel() {
  try {
    ws.subscribe(`Lot_${route.params.id}`, 'subscribeLot')
  } catch (e) {
    ConsoleLogger.defaultLogWarn({ groupName: 'WebSocket', path: 'VLotDetail', log: e })
  }
})()

const createHeaderDetailPage = (data) => {
  headerDetailPage[0].description = data.started_at
  headerDetailPage[1].description = data.duration
  headerDetailPage[2].description = data.status?.name
}

const getBestPricePosition = (positions, offers) => {
  if (!offers.length) {
    // eslint-disable-next-line no-return-assign
    positions.forEach(p => p.bestPrice = p.price)
    return
  }

  offers.forEach(offer => {
    const position = positions.find(pos => pos.id === offer.position_id)

    if (!position.bestPrice) {
      position.bestPrice = offer.price
      return
    }

    if (offer.price < position.bestPrice) {
      position.bestPrice = offer.price
    }
  })

  positions.forEach(ps => {
    if (!ps.bestPrice) {
      ps.bestPrice = ps.price
    }
  })
}
const setHistoryOffers = (cards) => {
  if (!cards.length) return
  cards.forEach(card => {
    if (card.updated_at) {
      card.dateCreate = card.updated_at.split(' ')[1]
      card.timeCreate = card.updated_at.split(' ')[0]
    }
    if (card.is_fake === undefined) {
      card.participant_name = ''
    } else {
      card.participant_name = card.user ? card.user.organization_name : 'не указано'
    }
    historyOffers.push(card)
  })
  // historyOffers.sort((a, b) => {
  //   const sortA = a?.sort
  //   const sortB = b?.sort
  //   return sortA > sortB ? -1 : 1
  // })
}

const isHideButtonCancelParticipant = ref(false)

const getLotService = async () => {
  try {
    startLoading()
    const { data } = await getLot(idPage)
    const response = await biddingProcedureController.getLastPrice(route.params.id)
    const cards = await biddingProcedureController.getBidderCardList(route.params.id)
    Object.assign(offers, response)
    createHeaderDetailPage(data)
    getBestPricePosition(data.position, offers.last_offers)
    setHistoryOffers(cards)
    store.commit('files/setFiles', data.file)
    store.commit('lot/setLot', data)
    store.commit('lot/setPositions', data.position)
    store.commit('lot/setLastPrice', offers)
    // For detail info lot
    Object.keys(data).forEach(key => {
      lot[key] = data[key]
    })

    // For added formData
    Object.keys(formData).forEach(key => {
      if (key === 'positions') {
        formData.positions = data.position
      } else {
        formData[key] = data[key]
      }
    })
    isOrganizer.value = lot.user?.id === store.getters['auth/getUser'].id
    isParticipant.value = participantService.checkIsParticipant(data)
    isFinishTrade.value = data.status.name === 'Торги завершены'
    if (!cards) return
    isHideButtonCancelParticipant.value = Boolean(cards.find(card => card.user_id === store.getters['auth/getUser'].id))
    isHideButtonCreateTrade.value = data.procedureType.id !== 2 && cards.find(card => card.user_id === store.getters['auth/getUser'].id)
  } catch (e) {
    console.log(e)
    await router.push({ name: 'login' })
  } finally {
    finishLoading()
  }
}

onBeforeMount(getLotService)
</script>

<template>
  <div class="lot-detail">
    <div class="lot-detail__body" v-if="!isLoading">
      <slot
        name="header"
        :headerInfo="headerDetailPage"
        :lot="lot"
        :is-organizer="isOrganizer"
      />
      <slot
        name="content"
        :lot="lot"
        :history-offers="historyOffers"
        :v$="v$"
        :validateField="validateField"
        :formData="formData"
        :server-errors="serverErrors"
        :is-bidder="isBidder"
        :is-bidder-active="isParticipant"
        :is-edit-delivery="isEditDelivery"
        :is-edit-payment="isEditPayment"
        :is-organizer="isOrganizer"
        :is-hide-btn="isHideButtonCreateTrade"
      />

      <slot
        name="action"
        :is-participant="isParticipant"
        :accept-participant="participantService.accept.bind(participantService)"
        :decline-participant="participantService.decline.bind(participantService)"
        :isFinishTrade="isFinishTrade"
        :send-bet="onSubmit"
        :is-send-bet="isSend"
        :lot="lot"
        :is-organizer="isOrganizer"
        :is-send-participant="isSendParticipant"
        :is-hide-btn="isHideButtonCreateTrade"
        :is-hide-button-cancel-participant="isHideButtonCancelParticipant"
      />

      <slot
        name="result"
      />
    </div>
    <div class="lot-detail__loader center" v-else>
      <VSpinnerLoader />
    </div>
  </div>
</template>
